import { gql } from '@/__generated__';

export const UserBadgeFragment = gql(`
fragment UserBadgeFragment on User {
  id
  fullName
  username
  avatar {
    key
  }
}
`);
