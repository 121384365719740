import { useFragment } from '@apollo/client';
import { useIsTablet } from '@synoptic/ui-kit/responsive/hooks.js';
import clsx from 'clsx';
import { UserBadgeFragment } from './user-badge-fragment';
import {
  userAvatar,
  userContainer,
  userHandle,
  userHandleInversed,
  userInfo,
  userName,
  userNameInversed,
  userNameUnderline,
} from './user-badge.css';
import { S3Avatar } from '../s3/user/s3-avatar';

export const UserBadge = ({
  id,
  size,
  underlineTitleOnHover = true,
  inversed,
}: {
  id: string;
  size?: 'medium' | 'small';
  underlineTitleOnHover?: boolean;
  inversed?: boolean;
}) => {
  const { data, complete } = useFragment({
    fragment: UserBadgeFragment,
    fragmentName: 'UserBadgeFragment',
    from: {
      __typename: 'User',
      id,
    },
  });
  const isTablet = useIsTablet();

  return complete ? (
    <div className={userContainer}>
      <S3Avatar
        className={userAvatar}
        size={size || (isTablet ? 'medium' : 'small')}
        name={data.fullName || ''}
        s3Key={data.avatar?.key}
        userId={data.id}
      />
      <div className={userInfo}>
        <span
          className={clsx(
            userName,
            underlineTitleOnHover && userNameUnderline,
            inversed && userNameInversed,
          )}
        >
          {data.fullName}
        </span>
        <span
          className={clsx(userHandle, inversed && userHandleInversed)}
          data-testid={'user-badge-username'}
        >{`@${data.username}`}</span>
      </div>
    </div>
  ) : null;
};
